<template>
  <div class="bidding">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="resultDialog" width="600" v-if="biddingResult">
      <v-card class="mx-auto" max-width="1000" outlined>
        <v-list-item four-line>
          <v-list-item-content>
            <div class="overline mb-4">
              BIDDING RESULT :
              <span style="color: green; font-weight: bold">{{
                biddingResult.result
              }}</span>
            </div>

            <v-list-item-title class="headline mb-1">{{
              biddingResult.productTitle
            }}</v-list-item-title>
            <v-list-item-subtitle
              >Expected Price : ${{
                biddingResult.expectPrice
              }}</v-list-item-subtitle
            >
            <div class="overline mb-4">
              Highest bidded price :
              <span style="color: red; font-weight: bold; font-size: 15px"
                >${{ biddingResult.latestBidPrice }}</span
              >
            </div>
          </v-list-item-content>

          <v-list-item-avatar tile size="100" color="grey">
            <img :src="biddingResult.carDefaultImg" />
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item three-line>
          <v-row>
            <v-col cols="6">
              <h4 style="text-decoration: underline">Seller Info:</h4>
              <br />
              <h4 style="padding-left: 20px">
                Account Type: {{ biddingResult.sellerDetail.accType }}
              </h4>
              <h4 style="padding-left: 20px">
                Name: {{ biddingResult.sellerDetail.name }}
              </h4>
              <h4 style="padding-left: 20px">
                Email: {{ biddingResult.sellerDetail.email }}
              </h4>
              <h4 style="padding-left: 20px">
                Phone: {{ biddingResult.sellerDetail.phone }}
              </h4>
              <h4 style="padding-left: 20px">
                Dealer Name:
                {{
                  biddingResult.sellerDetail.dealerName
                    ? biddingResult.sellerDetail.dealerName
                    : ""
                }}
              </h4>
              <h4 style="padding-left: 20px">
                Dealer Phone: {{ biddingResult.sellerDetail.dealerPhone }}
              </h4>
            </v-col>

            <v-col
              cols="6"
              v-for="(latestBidderDetail,
              index) in biddingResult.top3BidderDetails"
              :key="index"
            >
              <h4 style="text-decoration: underline">
                Bidder Info
                <span style="color: red">[{{ resultRankList[index] }}]</span>:
              </h4>
              <br />
              <h4 style="padding-left: 20px;">
                Bid Price:
                <span style="color: red"
                  >${{ latestBidderDetail.bidPrice }}</span
                >
              </h4>
              <h4 style="padding-left: 20px">
                Account Type: {{ latestBidderDetail.accType }}
              </h4>
              <h4 style="padding-left: 20px">
                Name: {{ latestBidderDetail.name }}
              </h4>
              <h4 style="padding-left: 20px">
                Email: {{ latestBidderDetail.email }}
              </h4>
              <h4 style="padding-left: 20px">
                Phone: {{ latestBidderDetail.phone }}
              </h4>
              <h4 style="padding-left: 20px">
                Dealer Name:
                {{
                  latestBidderDetail.dealerName
                    ? latestBidderDetail.dealerName
                    : ""
                }}
              </h4>
              <h4 style="padding-left: 20px">
                Dealer Phone: {{ latestBidderDetail.dealerPhone }}
              </h4>
              <br />
            </v-col>
          </v-row>
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editScheduleDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Bidding Schedule
        </v-card-title>
        <v-card-text>
          <div style="margin-top: 16px">Bid Start Time:</div>
          <v-datetime-picker v-model="editBiddingSchedule.bidStartTime">
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>

          <div style="margin-top: 16px">Bid End Time:</div>
          <v-datetime-picker v-model="editBiddingSchedule.bidEndTime">
            <template slot="dateIcon">
              <v-icon>Date</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>Time</v-icon>
            </template>
          </v-datetime-picker>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditBiddingSchedule()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bidderInfoDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Dealer name:
          {{ biddingWinnerInfo.dealerName ? biddingWinnerInfo.dealerName : "" }}
        </v-card-title>
        <br />
        <v-card-text>
          <!-- <img :src="biddingWinnerInfo.carDefaultImg" /> -->
          <p>Account Type: {{ biddingWinnerInfo.accType }}</p>
          <p>Name: {{ biddingWinnerInfo.name }}</p>
          <p>Email: {{ biddingWinnerInfo.email }}</p>
          <p>Phone: {{ biddingWinnerInfo.phone }}</p>
        </v-card-text>
        <v-btn
          absolute
          right
          bottom
          color="red accent-2"
          @click="showEditRemarksDialog(biddingWinnerInfo)"
        >
          <v-icon color="#fff">mdi-plus</v-icon>
          <span style="color: white">Remarks</span>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editRemarksDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ biddingWinnerInfo.name }} Remarks
        </v-card-title>

        <v-card-text>
          <v-select
            v-model="biddingWinnerInfo.remarks"
            :items="biddingWinnerInfoList"
            item-value="value"
            item-text="text"
            label="Remarks"
          >
          </v-select>
          <v-text-field
            v-model="biddingWinnerInfo.zValue"
            label="Z value"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="
              doEditRemarks(
                biddingWinnerInfo.biddingId,
                biddingWinnerInfo.remarks,
                biddingWinnerInfo.zValue
              )
            "
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editBidding.name }} Status
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="editBidding.status"
            :items="biddingStatusList"
            item-value="value"
            item-text="text"
            label="Bidding Status"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditBidding()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchBidding()"
          v-model="biddingSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchBidding()"
          v-model="biddingCarPlate"
          label="Car Plate Number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="biddingStatus"
          :items="biddingStatusList"
          item-value="value"
          item-text="text"
          label="Bidding Status"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchBidding()"
          @keyup.enter.native="searchBidding()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="biddingList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>
            {{ row.item.productTitle }}
            <span style="color: red">
              {{ row.item.inspected == "yes" ? "[INSPECTED]" : "" }}</span
            >
          </td>
          <td>{{ new Date(row.item.bidStartTime) }}</td>
          <td>{{ new Date(row.item.bidEndTime) }}</td>
          <td>{{ row.item.maxReservePrice }}</td>
          <td>
            <span v-if="row.item.biddingWinnerInfo">
              {{
                row.item.biddingWinnerInfo.dealerName
                  ? row.item.biddingWinnerInfo.dealerName
                  : ""
              }}
            </span>
          </td>
          <td>{{ row.item.editorNotes }}</td>
          <td>{{ row.item.status }}</td>
          <td>{{ row.item.zvalue }}</td>
          <td style="text-align: center">
            <input type="file" @change="Images_onFileChanged" />
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="black"
              @click="uploadButtonClick(row.item)"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
            <p style="padding-top: 10px">
              <a :href="row.item.inspectedFilePath" target="_blank">PDF</a>
            </p>
            <!-- <v-switch
              v-model="inspectedToggle"
              @change="onToggleInspect(row.item)"
            ></v-switch> -->
          </td>

          <td>
            <v-switch
              v-model="row.item.hideFlag"
              @change="onToggleHide(row.item)"
            ></v-switch>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="black"
              @click="resultButtonClick(row.item)"
            >
              <v-icon>mdi-trophy</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="green"
              @click="editScheduleButtonClick(row.item)"
            >
              <v-icon>mdi-clock</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="orange"
              @click="winnerInfoButtonClick(row.item)"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </td>
          <td>{{ row.item.remarks }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";

import uploadImage from "../../../services/image-uploader";
import uploadVideo from "../../../services/video-uploader";
import uploadInspectedPdf from "../../../services/inspected-uploader";

import postAxios from "../../../services/axios-post";

import DatetimePicker from "vuetify-datetime-picker";

Vue.use(DatetimePicker);
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      inspectedToggle: null,
      inspectedFilePathLocal: null,
      // biddingResults: [],
      type: null,
      alertMsg: null,
      biddingSearch: "",
      biddingCarPlate: "",
      biddingStatus: "",
      token: "",
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      defaultImgPath: null,
      imgUrl: null,
      coeDateModal: false,
      regDateModal: false,
      renewDateModal: false,
      dialog: false,
      editRemarksDialog: false,
      editDialog: false,
      editScheduleDialog: false,
      biddingResultId: null,
      resultDialog: false,
      snackbar: false,
      snackMessage: "",
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      salepersonJSON: null,
      dealerJSON: null,
      carModelJSON: null,
      search: null,
      searchDealer: null,
      searchSalesperson: null,
      addUsedCar: {
        arfPrice: 0,
        carModelCode: "",
        carPlateNumber: "",
        coeCategoryId: "",
        coeDate: new Date().toISOString().substr(0, 10),
        coePrice: 0,
        coeType: "",
        color: "",
        defaultImgPath: "",
        depreciation: 0,
        displayImgs: [],
        draft: false,
        efficiency: 0,
        idNum: "",
        idTypeId: "",
        isDraft: false,
        mileage: 0,
        notes: "",
        numOfOwner: 0,
        offPeakCarFlag: 0,
        omvPrice: 0,
        ownerName: "",
        priceHiddenFlag: 0,
        productTitle: "",
        regDate: new Date().toISOString().substr(0, 10),
        renewCoeDate: new Date().toISOString().substr(0, 10),
        roadTax: 0,
        salePrice: 0,
        salepersonIds: [],
        scrapValue: 0,
        vesCategoryId: "",
        videoPaths: [],
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "productTitle" },
        { text: "Bid Start Time", value: "bidStartTime" },
        { text: "Bid End Time", value: "bidEndTime" },
        { text: "Max Reserved Price", value: "maxReservePrice" },
        {
          text: "Current Highest Dealer",
          value: "biddingWinnerInfo",
          sortable: false,
        },
        { text: "Notes", value: "editorNotes" },
        { text: "Status", value: "status" },
        { text: "Z Value", value: "zvalue" },
        { text: "Inspected", value: "inspected", sortable: false },
        { text: "Hide", value: "hide", sortable: false },
        { text: "Result", value: "result", sortable: false },
        { text: "Edit Timing", sortable: false },
        { text: "Approval", sortable: false },
        { text: "WinnerInfo", sortable: false },
        { text: "WinnerRemarks", value: "remarks" },
      ],
      biddingWinnerInfo: {
        biddingId: null,
        remarks: null,
        accType: null,
        dealerId: null,
        dealerName: null,
        dealerPhone: null,
        email: null,
        name: null,
        phone: null,
        zValue: null,
      },
      editBiddingSchedule: {
        id: null,
        bidEndTime: new Date(),
        bidStartTime: new Date(),
      },
      bidderInfoDialog: false,
      biddingList: [],
      displayImgsFiles: [],
      displayVideoFiles: [],
      salespersonEntries: [],
      dealerEntries: [],
      coeTypeList: [],
      idTypeList: [],
      vesCategoryList: [],
      coeCatList: [],
      biddingResult: null,
      resultRankList: ["WINNER", "SECOND", "THIRD"],
      isLoading: false,
      editBidding: {
        name: "",
        biddingId: "",
        status: "",
      },
      biddingStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "REJECTED", value: "REJECTED" },
        { text: "SOLD", value: "ENDED_SOLD" },
        { text: "NOT SOLD", value: "ENDED_NOT_SOLD" },
      ],
      biddingWinnerInfoList: [
        { text: "Pending Viewing", value: "Pending Viewing" },
        { text: "Car Sold", value: "Car Sold" },
        { text: "Pending", value: "Pending" },
        { text: "Price Too Low", value: "Price Too Low" },
        { text: "Considering", value: "Considering" },
      ],
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchBiddingList();
    this.fetchVesCat();
    this.fetchCOECat();
    this.fetchCOEType();
    this.fetchIDType();
  },
  computed: {
    items() {
      return this.entries;
    },
    salesPersonItems() {
      return this.salespersonEntries;
    },
    dealershipItems() {
      return this.dealerEntries;
    },
  },
  watch: {
    // Items have already been loaded
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carModelPair?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.entries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchSalesperson() {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/dealerSaleperson`,
        {
          dealerId: self.dealerJSON.id,
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.salespersonEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchDealer(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        {
          searchContent: val,
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.dealerEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (sortBy[0] === "productTitle") {
        columnName = "car.productTitle";
      } else if (sortBy[0] === "bidStartTime") {
        columnName = "biddingSchedule.bidStartTime";
      } else if (sortBy[0] === "bidEndTime") {
        columnName = "biddingSchedule.bidEndTime";
      } else if (sortBy[0] === "maxReservePrice") {
        columnName = "latestPrice";
      } else {
        columnName = sortBy[0];
      }
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = "createTime,desc";
      }
      this.fetchBiddingList();
    },
    getLastBiddingPrice(biddingId) {
      const filterItem = encodeURI(`[["bidding.id", "eq", "${biddingId}"]]`);
      const sortItem = encodeURIComponent(`bidPrice,desc`);
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/car-bid-record?filter=${filterItem}&sort=${sortItem}`,
        this.token
      ).then(res => {
        if (res.data) {
          this.biddingResultId = res.data.items[0].id;
        }
      });
    },
    Images_onFileChanged(event) {
      this.inspectedFilePathLocal = event.target.files[0];
    },
    editScheduleButtonClick(item) {
      this.editBiddingSchedule.id = item.biddingScheduleId;
      this.editBiddingSchedule.bidEndTime = new Date(item.bidEndTime);
      this.editBiddingSchedule.bidStartTime = new Date(item.bidStartTime);
      this.editScheduleDialog = true;
    },
    async doEditBiddingSchedule() {
      var self = this;
      try {
        const params = {
          id: self.editBiddingSchedule.id,
          bidEndTime: self.editBiddingSchedule.bidEndTime,
          bidStartTime: self.editBiddingSchedule.bidStartTime,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBiddingSchedule`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editScheduleDialog = false;
              self.fetchBiddingList();
              self.showAlert(
                "success",
                "Bidding Schedule Edited Successfully!"
              );
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async uploadButtonClick(item) {
      const self = this;
      try {
        if (
          this.inspectedFilePathLocal != null &&
          this.inspectedFilePathLocal.type != "application/pdf"
        ) {
          self.showAlert("error", "Please select a PDF to upload");
          return;
        }
        if (this.inspectedFilePathLocal == null) {
          item.inspected = "no";
        } else {
          const uploadRes = await uploadInspectedPdf(
            this.inspectedFilePathLocal
          );
          if (uploadRes.data.httpStatus !== "OK") {
            return;
          }
          item.inspectedFilePath = uploadRes.data.data;
          item.inspected = "yes";
        }
        this.doEditInspected(item.id, item);
      } catch (err) {
        console.log(err);
      }
    },
    resultButtonClick(item) {
      const self = this;
      const params = {
        biddingScheduleId: item.biddingScheduleId,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/generateBiddingReport`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            var arr = res.data.data;
            self.biddingResult = arr;
            this.bidPrice =
              this.biddingResult.top3BidderDetails.length > 0
                ? this.biddingResult.top3BidderDetails[0].bidPrice
                : null;
            this.getLastBiddingPrice(self.biddingResult.biddingId);
          }
        })
        .catch(function(error) {
          self.biddingResult = null;
          self.showAlert("error", error.response.data.message);
        });
      this.resultDialog = true;
    },
    chooseImage() {
      this.addUsedCar.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    async doAddUsedCar() {
      const self = this;
      try {
        const imgRes = await uploadImage(self.defaultImgPath);
        if (imgRes.data.httpStatus !== "OK") {
          return;
        }
        let displayImgArray = [];
        for (let i = 0; i < this.displayImgsFiles.length; i++) {
          let imageRes = await uploadImage(self.displayImgsFiles[i]);
          if (imageRes.data.httpStatus === "OK") {
            displayImgArray.push(imgRes.data.data);
          }
        }
        let displayVideoArray = [];
        for (let i = 0; i < this.displayVideoFiles.length; i++) {
          let videoRes = await uploadVideo(self.displayVideoFiles[i]);
          if (videoRes.data.httpStatus === "OK") {
            displayVideoArray.push(imgRes.data.data);
          }
        }
        self.addUsedCar.videoPaths = displayVideoArray;
        self.addUsedCar.displayImgs = displayImgArray;
        self.addUsedCar.defaultImgPath = imgRes.data.data;
        self.addUsedCar.carModelCode = self.carModelJSON.code;
        self.addUsedCar.salepersonIds.push(self.salepersonJSON.id);
        // console.log("used car: " + JSON.stringify(self.addUsedCar));
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/addCarUsedForDealer?dealerId=` +
            self.dealerJSON.id,
          self.addUsedCar,
          self.token
        ).then(res => {
          if (res.data) {
            self.dialog = false;
            self.fetchUsedCar();
            self.showSnack("Add Car Successfully", "success");
          }
        });
      } catch (e) {
        self.showSnack("Add Car Failed" + e, "error");
        console.error(e);
      }
    },
    searchBidding() {
      this.fetchBiddingList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchBiddingList();
    },
    editButtonClick(item) {
      this.editBidding.name = item.name;
      this.editBidding.status = item.status;
      this.editBidding.biddingId = item.id;

      this.editDialog = true;
    },
    async doEditBidding() {
      const self = this;

      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBiddingStatus?biddingId=` +
            this.editBidding.biddingId +
            "&status=" +
            this.editBidding.status,
          this.editBidding,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.fetchBiddingList();
            self.$message({
              message: "Bidding Edited Successfully!",
              type: "success",
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    fetchBiddingList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.biddingSearch,
        sort: self.sortValue,
        status: self.biddingStatus,
        carPlate: self.biddingCarPlate,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/bidding`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.biddingList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchVesCat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/vesCategory`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.vesCategoryList = res.data.data;
        }
      });
    },
    fetchCOECat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeCategoryPair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeCatList = res.data.data.content;
        }
      });
    },
    fetchCOEType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/categoryType`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeTypeList = res.data.data;
        }
      });
    },
    fetchIDType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/idTypePair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.idTypeList = res.data.data.content;
        }
      });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    winnerInfoButtonClick(item) {
      if (item.biddingWinnerInfo) {
        this.biddingWinnerInfo.accType = item.biddingWinnerInfo.accType;
        this.biddingWinnerInfo.dealerName = item.biddingWinnerInfo.dealerName
          ? item.biddingWinnerInfo.dealerName
          : "";
        this.biddingWinnerInfo.dealerPhone = item.biddingWinnerInfo.dealerPhone;
        this.biddingWinnerInfo.email = item.biddingWinnerInfo.email;
        this.biddingWinnerInfo.name = item.biddingWinnerInfo.name;
        this.biddingWinnerInfo.phone = item.biddingWinnerInfo.phone;
        this.biddingWinnerInfo.zValue = item.biddingWinnerInfo.zvalue;
      }
      this.biddingWinnerInfo.biddingId = item.id;
      this.biddingWinnerInfo.remarks = item.remarks ? item.remarks : "";
      this.biddingWinnerInfo.zValue = item.zvalue ? item.zvalue : "";
      this.bidderInfoDialog = true;
    },
    showEditRemarksDialog(item) {
      this.editRemarksDialog = true;
      console.log(item);
    },

    doEditRemarks(biddingId, remarks, zValue) {
      const self = this;
      try {
        const data = {
          biddingId: biddingId,
          remarks: remarks,
          zValue: zValue,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchBiddingList();
            self.editRemarksDialog = false;
            self.showAlert("success", "Remarks Edited Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          biddingId: item.id,
          hideFlag: item.hideFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchBiddingList();
            self.editRemarksDialog = false;
            self.showAlert("success", "Hide updated Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
    onToggleInspect(item) {
      const self = this;
      try {
        const data = {
          biddingId: item.id,
          inspected: item.inspected ? "yes" : "no",
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchBiddingList();
            self.showAlert("success", "Inspected updated Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },

    doEditInspected(biddingId, item) {
      const self = this;
      try {
        const data = {
          biddingId: biddingId,
          inspectedFilePath: item.inspectedFilePath,
          inspected: item.inspected,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/updBidding`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchBiddingList();
            self.editRemarksDialog = false;
            self.showAlert("success", "Updated Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
      }
    },
  },
};
</script>
<style>
.text-field {
  padding: 0;
  margin: 0;
}
.text-field .v-text-field__details {
  display: none !important;
}
.text-field input {
  color: red !important;
}
</style>
