import axios from "axios";
import helper from "./helper";

import objectToFormData from "../utils/objectToFormData";

const uploadInspectedPdf = (file, config) => {
  const { size } = file;
  if (size > 5000000) {
    throw new Error("Image size has to be < 5 MB");
  }
  const options = {
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("jwt"),
      "c-time": helper.getDateTime(),
      "x-api-key": helper.sha256(
        process.env.VUE_APP_API_KEY + helper.getDateTime()
      ),
    },
  };
  return axios.post(
    `${process.env.VUE_APP_SERVER_URL}/file/uploadInspectedPdf`,
    objectToFormData({
      file,
    }),
    options,
    config
  );
};

export default uploadInspectedPdf;
